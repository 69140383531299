<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} BRAND</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- Product Image -->
        <template v-if="image">

          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="image" alt="img" class="responsive">
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Update Image</vs-button>
            <vs-button type="flat" color="#999" @click="form.logo = null; image = null">Remove Image</vs-button>
          </div>
        </template>

        <!-- English NAME -->
        <vs-input label="English Name" v-model="form.name_en" class="mt-5 w-full" name="name_en"/>
        <span class="text-danger text-sm" v-show="errors.has('name_en')">{{ errors.first("name_en") }}</span>

        <!-- Arabic NAME -->
        <vs-input label="Arabic Name" v-model="form.name_ar" class="mt-5 w-full" name="name_ar"/>
        <span class="text-danger text-sm" v-show="errors.has('name_ar')">{{ errors.first("name_ar") }}</span>

        <vs-input label="Hotline" v-model="form.hotline" class="mt-5 w-full" name="hotline" type="number"/>
        <span class="text-danger text-sm" v-show="errors.has('hotline')">{{ errors.first("hotline") }}</span>

        <div class="mt-5 w-full">
          <label class="vs-input--label">Show on Mobile</label>
          <vs-switch v-model="form.mobile_active"/>
        </div>

        <!-- Upload -->
        <div class="upload-img mt-5" v-if="!form.logo">
          <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button @click="$refs.uploadImgInput.click()">Upload Logo</vs-button>
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from "vue-perfect-scrollbar";

  export default {
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        }
      }
    },
    mounted() {
      console.log("data", JSON.stringify(this.data));
    },
    data() {
      return {
        form: {
          name_en: "",
          name_ar: "",
          car_type: 1,
          logo: null,
          mobile_active: false,
          hotline: ""
        },
        image: null,
        id: null,
        settings: {
          maxScrollbarLength: 150,
          wheelSpeed: .80
        }
      };
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return;
        if (Object.entries(this.data).length === 0) {
          this.initValues();
          this.$validator.reset();
        } else {
          let { id, name_en, name_ar, logo, car_type, mobile_active, hotline } = JSON.parse(JSON.stringify(this.data));
          this.id = id;
          this.form = {
            name_en,
            name_ar,
            logo,
            car_type,
            mobile_active,
            hotline
          };
          this.image = logo;
          this.initValues();
        }
      }
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive;
        },
        set(val) {
          if (!val) {
            this.$emit("closeSidebar");
            this.$validator.reset();
            this.initValues();
          }
        }
      },
      isFormValid() {
        return !this.errors.any() && this.form.logo && this.form.hotline;
      }
    },
    methods: {
      initValues() {
        if (this.data.id) return;
        this.id = null;
        this.form = {
          name_en: "",
          name_ar: "",
          car_type: 1,
          logo: null,
          mobile_active: false,
          hotline: ""
        };
        this.image = null;
        this.id = null;
      },
      submitData() {
        this.$validator.validateAll().then(result => {
          this.$vs.loading();
          if (result) {
            const obj = this.form;

            const formData = new FormData();

            formData.append("name_en", obj.name_en);
            formData.append("name_ar", obj.name_ar);
            formData.append("car_type", obj.car_type);
            formData.append("hotline", obj.hotline);
            formData.append("mobile_active", Number(obj.mobile_active));

            if (this.id !== null && this.id >= 0) {
              if (!/^(ftp|http|https):\/\/[^ "]+$/.test(obj.logo)) {
                formData.append("logo", obj.logo);
                console.log("logo:" + obj.logo);
              }
              this.$httpCars.post("/brands/" + this.id, formData, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }).then(() => {
                const message = "Brand has been updated successfully";
                this.$vs.notify({
                  title: "Successful operation",
                  text: message,
                  color: "success"
                });
                this.$emit("onSuccess");
              }).catch(() => {
                // console.log({err});

                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Something Error 🙁",
                  text: "Couldn't add data to the server",
                  color: "danger"
                });
              }).finally(() => {
                this.$emit("closeSidebar");
                this.initValues();
                this.$vs.loading.close();
              });
            } else {
              formData.append("logo", obj.logo);
              this.$httpCars.post("/brands", formData, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }).then(() => {
                const message = "Brand has been created successfully";
                this.$vs.notify({
                  title: "Successful operation",
                  text: message,
                  color: "success"
                });
                this.$emit("onSuccess");
              }).catch(() => {
                //console.log({err});

                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Something Error 🙁",
                  text: "Couldn't add data to the server",
                  color: "danger"
                });
              }).finally(() => {
                this.$emit("closeSidebar");
                this.initValues();
                this.$vs.loading.close();
              });
            }

            this.$emit("closeSidebar");
            this.initValues();
            this.$vs.loading.close();
          }
        });
      },
      updateCurrImg(input) {
        if (input.target.files && input.target.files[0]) {
          let reader = new FileReader();
          reader.onload = e => {
            this.image = e.target.result;
          };
          this.form.logo = input.target.files[0];
          reader.readAsDataURL(input.target.files[0]);
        }
      }
    },
    components: {
      VuePerfectScrollbar
    }
  };
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  }
</style>
