<template>
  <div>
    <breadcrumb name="Brands" title="Mansour Brands" subtitle="List"/>

    <div id="data-list-thumb-view" class="data-list-container">

      <data-view-sidebar :isSidebarActive="addNewDataSidebar" @onSuccess="onSuccessOperation" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

      <vs-table
        ref="table"
        :sst="true"
        @search="handleSearch"
        @sort="handleSort"
        search
        :data="items">

        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <add-new-button @click="addNewData()" />
          </div>

          <!-- ITEMS PER PAGE -->
          <table-header
            :current-page="currentPage"
            :items-per-page="itemsPerPage"
            :length="items.length"
            :queried-items="queriedItems"
            @onChangeItemsPerPage="itemsPerPage = $event"/>
        </div>

        <template slot="thead">
          <vs-th>Logo</vs-th>
          <vs-th sort-key="name_en">English Name</vs-th>
          <vs-th sort-key="name_ar">Arabic Name</vs-th>
          <vs-th sort-key="mobile_active">Status</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

            <image-row :data="tr.logo" />
            <string-row :data="tr.name_en" />
            <string-row :data="tr.name_ar" />
            <status-row :data="tr.mobile_active" />
            <actions-row
              @onEditClicked="editData(tr)"
              @onDeleteClicked="deleteData(tr.id)"/>

          </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
    <vs-pagination :total="totalItems" v-model="page"/>
  </div>
</template>

<script>
    import DataViewSidebar from './data-view-sidebar';
    import breadcrumb from '../../../../components/general/breadcrumb';
    import TableHeader from "../../../../components/table/table-header";
    import StringRow from "../../../../components/table/rows/string-row";
    import ImageRow from "../../../../components/table/rows/image-row";
    import StatusRow from "../../../../components/table/rows/status-row";
    import ActionsRow from "../../../../components/table/rows/actions-row";
    import AddNewButton from "../../../../components/form/addnew-button";

    export default {
        components: {
          AddNewButton,
            ActionsRow,
            StatusRow,
            ImageRow,
            StringRow,
            TableHeader,
            DataViewSidebar,
            breadcrumb
        },
        data() {
            return {
                items: [],
                itemsPerPage: 20,
                isMounted: false,
                page: 1,
                totalItems:0,
                query: "",
                sortKey: null,
                sortActive: null,

                // Data Sidebar
                addNewDataSidebar: false,
                sidebarData: {},
            }
        },
        computed: {
            currentPage() {
                if(this.isMounted) {
                    return this.$refs.table.currentx
                }
                return 0
            },
            queriedItems() {
                return this.$refs.table ? this.$refs.table.queriedResults.length : this.items.length
            }
        },
        watch: {
            page() {
                this.getData()
            },
            query() {
                this.getData()
            },
            sortKey() {
                this.getData()
            },
            sortActive() {
                this.getData()
            },
            itemsPerPage() {
                this.getData()
            }
        },
        methods: {
            onSuccessOperation() {
                this.getData();
            },
            addNewData() {
                this.sidebarData = {};
                this.toggleDataSidebar(true)
            },
            deleteData(id) {
                this.$vs.loading();
                this.$httpCars
                    .delete(`/brands/${id}`)
                    .then(() => {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: "Successful operation",
                            text: "Brand has been deleted successfully",
                            color: 'success'
                        });
                        this.getData();
                    })
                    .catch(() => {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: "Something Error 🙁",
                            text: "Couldn't delete data on the server",
                            color: 'danger'
                        });
                    })
            },
            editData(data) {
                this.sidebarData = data;
                this.toggleDataSidebar(true)
            },
            toggleDataSidebar(val=false) {
                this.addNewDataSidebar = val
            },
            handleSearch(query) {
                this.query = query
            },
            handleSort(key, active) {
                this.sortKey = key;
                this.sortActive = active === 'asc' ? 1 : 0;
            },
            getData() {
                this.$vs.loading();
                const queryString = `per_page=${this.itemsPerPage}&page=${this.page}&query=${this.query}&field=${this.sortKey}&ordering=${this.sortActive}`;
                this.$httpCars
                    .get(`/brands?${queryString}`)
                    .then(res => {
                        this.$vs.loading.close();
                        this.items = res.data.data.brands;
                        this.totalItems = Math.ceil(res.data.data.total / this.itemsPerPage);
                    })
                    .catch(() => {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: "Something Error",
                            text: "Couldn't fetch data from the server",
                            color: 'danger'
                        });
                    })
            }
        },
        mounted() {
            this.isMounted = true;
            this.getData()
        }
    }
</script>

<style lang="scss">
  #data-list-list-view {
    .vs-con-table {

      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search{
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            &+i {
              left: 1rem;
            }

            &:focus+i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
</style>
